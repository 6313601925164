export default class auxiliarFunctions {
  static zeroPad(num, places) {
    return String(num).padStart(places, "0");
  }

  static datetimeString(ts) {
    let date = new Date(ts * 1000);
    let hours = this.zeroPad(date.getHours(), 2);
    let minutes = this.zeroPad(date.getMinutes(), 2);
    let seconds = this.zeroPad(date.getSeconds(), 2);
    let day = this.zeroPad(date.getDate(), 2);
    let month = this.zeroPad(date.getMonth() + 1, 2);
    let year = date.getFullYear();
    return (
      day +
      "/" +
      month +
      "/" +
      year +
      " - " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  }

  static downloadFile(content, filename) {
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(content)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    element.click();
  }

  static timeSub(offset, duration) {
    let number = duration == 0 ? offset : offset + duration;
    let milisecondsOffset = auxiliarFunctions.zeroPad(number % 1000, 3);
    let seconds = auxiliarFunctions.zeroPad(Math.floor(number / 1000) % 60, 2);
    let minutes = auxiliarFunctions.zeroPad(
      Math.floor((number / 1000 / 60) % 60),
      2
    );
    let hours = auxiliarFunctions.zeroPad(
      Math.floor(number / 1000 / 60 / 60),
      2
    );
    return hours + ":" + minutes + ":" + seconds + "." + milisecondsOffset;
  }
}
